// /* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react';
import GlobalStyle from './src/utils/styles/global';
import theme from './src/utils/styles/theme';
import { ThemeProvider } from 'styled-components';
import { AnimationProvider } from 'context/AnimationProvider';
import Footer from 'components/Footer';
import Header from 'components/Header';
import IntViewportHeight from 'components/IntViewportHeight';
import VideoBackground from 'components/VideoBackground';
import SEO from 'components/SEO';
import ArticleProgressBar from 'components/ArticleProgressBar';

import './app.css';
import 'swiper/swiper-bundle.min.css';

const wrapPageElement = ({ element, props }) => {
  const { hasBackground, headerColor, footerColor, name } = props.pageContext;
  const is404 = name === '404';
  const isArticle = !!props?.data?.contentfulArticlePage;
  const isBackgroundFooterBlue = !hasBackground && footerColor === 'white';

  const seo = props?.data?.contentfulHomePage?.seo || props?.pageContext.seo

  return (
    <>
      <SEO
        {...seo}
        location={props?.location}
        locale={props?.pageContext?.locale}
        pageContext={props?.pageContext}
      />
      <ThemeProvider theme={theme}>
        <AnimationProvider>
          <GlobalStyle />
          {isArticle ? <ArticleProgressBar /> : <></>}
          <Header isArticle={isArticle} headerColor={headerColor} />
          <IntViewportHeight>
            <main id="main">
              {hasBackground && <VideoBackground />}
              {element}
            </main>
            {is404 ? (
              <></>
            ) : isBackgroundFooterBlue ? (
              <Footer
                backgroundColor="BackgroundBlue"
                footerColor={footerColor}
              />
            ) : (
              <Footer footerColor={footerColor} />
            )}
          </IntViewportHeight>
        </AnimationProvider>
      </ThemeProvider>
    </>
  );
};

export default wrapPageElement;
