import React, { createContext, useState } from 'react';

const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
  const [animationExit, setAnimationExit] = useState(false);

  return (
    <AnimationContext.Provider
      value={{
        animationExit,
        setAnimationExit,
      }}
    >
      {children}
    </AnimationContext.Provider>
  );
};

export default AnimationContext;
