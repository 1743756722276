import styled from 'styled-components';

export const ContainerWrapperStyled = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: ${(props) =>
    props.topSpacingMobile || props.topSpacingMobile === 0
      ? `${props.topSpacingMobile}px`
      : '0'};
  padding-bottom: ${(props) =>
    props.bottomSpacingMobile || props.bottomSpacingMobile === 0
      ? `${props.bottomSpacingMobile}px`
      : '0'};
  width: ${(props) =>
    props.widthMobile ? props.widthMobile : `calc(100% - 26px)`};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-top: ${(props) =>
      props.topSpacingTablet || props.topSpacingTablet === 0
        ? `${props.topSpacingTablet}px`
        : '0'};
    padding-bottom: ${(props) =>
      props.bottomSpacingTablet || props.bottomSpacingTablet === 0
        ? `${props.bottomSpacingTablet}px`
        : '0'};
    width: ${(props) =>
      props.widthTablet ? props.widthTablet : `calc(100% - 60px)`};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-top: ${(props) =>
      props.topSpacingDesktop || props.topSpacingDesktop === 0
        ? `${props.topSpacingDesktop}px`
        : '0'};
    padding-bottom: ${(props) =>
      props.bottomSpacingDesktop || props.bottomSpacingDesktop === 0
        ? `${props.bottomSpacingDesktop}px`
        : '0'};
    width: calc(100% - 300px);
    max-width: ${(props) => (props.cap ? `${props.cap}px` : '1140px')};
  }
`;

export const ContainerStyled = styled.div`
  padding-left: ${(props) => (props.spacedMobile ? '20px' : '0px')};
  padding-right: ${(props) => (props.spacedMobile ? '20px' : '0px')};
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.containerBackgroundColor};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-left: ${(props) => (props.spacedTablet ? '30px' : '0px')};
    padding-right: ${(props) => (props.spacedTablet ? '30px' : '0px')};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-left: ${(props) => (props.spacedDesktop ? '60px' : '0px')};
    padding-right: ${(props) => (props.spacedDesktop ? '60px' : '0px')};
  }
`;
