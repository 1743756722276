import { useEffect } from 'react';

export const useDisableBodyScroll = (open) => {
  useEffect(() => {
    const isBrowser = typeof window !== `undefined`;
    if (!isBrowser) return null;
    if (open) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  
      window.onscroll = function() {
          window.scrollTo(scrollLeft, scrollTop);
      };

    } else {
      document.body.style.cssText = '';
      window.onscroll = function() {};
    }
  }, [open]);
};
