import { gsap, Power1 } from 'gsap';

const linksAnimation = (ref) => {
  const linkLines = ref.current.querySelectorAll('#site-link-line');
  const linkIcons = ref.current.querySelectorAll('svg');
  const linkTitle = ref.current.querySelectorAll('#site-link-body');
  const linkBody = ref.current?.querySelectorAll('p');

  const tl = gsap
    .timeline()
    .fromTo(ref.current, { opacity: 0 }, { opacity: 1, duration: 0 })
    .fromTo(
      linkLines,
      { width: 0 },
      { width: '100%', stagger: 0.3, duration: 0.8, ease: Power1.easeOut },
      '+=0.8'
    )
    .fromTo(
      linkTitle,
      { y: '200%' },
      { y: 0, stagger: 0.2, duration: 0.8, ease: Power1.easeOut },
      '-=1.5'
    )
    .fromTo(
      linkIcons,
      { autoAlpha: 0, x: '-30%' },
      { autoAlpha: 1, x: 0, stagger: 0.3, duration: 0.5, ease: Power1.easeOut },
      '-=1.5'
    )
    .fromTo(linkBody, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power1.easeOut });

  return tl;
};

export default linksAnimation;
