import styled from 'styled-components';

export const VideoBgWrapper = styled.div`
  pointer-events: none;

  & > * {
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 68, 115, 0.3),
        rgba(0, 68, 115, 0.3)
      );
      backdrop-filter: blur(10px);
    }
  }

  video {
    display: block;
  }
`;
