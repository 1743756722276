import styled, { css } from 'styled-components';
import Link from 'gatsby-plugin-transition-link';
import { h1 } from 'components/UI/Typography/styles';
import { h3 } from 'components/UI/Typography/styles';

export const SiteLinkWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    display: flex;
    flex-direction: column;
  }

  &.primary > hr {
    display: inline-block;
    background-color: ${(props) => props.theme.colors.white};
  }

  &.secondary > hr {
    display: inline-block;
    background-color: ${(props) => props.theme.colors.blue};
  }
`;

export const SiteLinkLine = styled.hr`
  display: none;
  width: 100%;
  height: 1px;
`;

export const ThemeLinkStyle = css`
  padding-top: ${(props) =>
    props.topSpacingMobile ? `${props.topSpacingMobile}px` : '15px'};
  padding-bottom: ${(props) =>
    props.bottomSpacingMobile ? `${props.bottomSpacingMobile}px` : '15px'};
  display: grid;
  grid-template-columns: 1fr 15px;
  grid-column-gap: 15px;
  text-align: left;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  transition: all 300ms linear;
  font-size: 17px;
  overflow-y: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-top: ${(props) =>
      props.topSpacingTablet ? `${props.topSpacingTablet}px` : '30px'};
    padding-bottom: ${(props) =>
      props.bottomSpacingTablet ? `${props.bottomSpacingTablet}px` : '30px'};
    grid-template-columns: 1fr 39px;
    grid-column-gap: 130px;
    ${h1};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-top: ${(props) =>
      props.topSpacingDesktop ? `${props.topSpacingDesktop}px` : '30px'};
    padding-bottom: ${(props) =>
      props.bottomSpacingDesktop ? `${props.bottomSpacingDesktop}px` : '30px'};
    grid-template-columns: 1fr 48.75px;
    grid-column-gap: 48.75px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    .Site-Link {
      display: block;
    }
  }

  .primary & {
    color: ${(props) => props.theme.colors.white};

    .Icon {
      path,
      circle {
        stroke: ${(props) => props.theme.colors.white};
      }
    }
  }

  .secondary & {
    color: ${(props) => props.theme.colors.blue};

    .Icon {
      path,
      circle {
        stroke: ${(props) => props.theme.colors.blue};
      }
    }
  }

  .isNavigation & {
    padding: 20px 0;
    ${h3};
    align-items: center;

    & .Site-Link-Body {
      padding-top: 3px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      padding: 30px 0;
    }
  }

  .Icon {
    grid-column: 2/3;
    grid-row: 2/2;
    align-self: center;
    width: 100%;
    height: 13px;

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      height: 32px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      height: 40.37px;
    }
  }
`;

export const SiteLikeLink = styled.button`
  width: 100%;
  ${ThemeLinkStyle};
`;

export const SiteLink = styled(Link)`
  ${ThemeLinkStyle};
`;

export const LinkBody = styled.div`
  padding-bottom: 0;
  grid-column: 1/2;
  grid-row: 2/2;
  transition: padding 500ms ease;
  will-change: padding;
  font-family: ${(props) => props.theme.fontFamily.secondary};
`;
