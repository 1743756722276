import { css } from 'styled-components';
import {
  p,
  small,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
} from 'components/UI/Typography/styles';

const theme = {
  colors: {
    white: '#ffffff',
    lime: '#CCFF00',
    dark_lime: '#C6D808',
    blue: '#006BB6',
    light_blue: 'rgba(0, 107, 182, 0.1)',
    black: '#212121',
    grey: '#C8C8C8',
    red: '#FF0000',
    green: '#4BB543',
  },
  breakpoints: {
    xxs: '375px',
    xs: '425px',
    s: '600px',
    m: '740px',
    md: '960px',
    l: '1200px',
    xl: '1400px',
    xxl: '1920px',
  },
  fontFamily: {
    primary: 'Inter, sans-serif',
    secondary: 'MonumentExtended, sans-serif',
  },

  // Naming should match html naming as much as possible

  fonts: {
    h1_large_desktop: '45px', // min 1960px
    h1_desktop: '45px', // min 1200px
    h1_tablet: '36px', // min 960px
    h1_mobile: '24px', // min 0

    h2_large_desktop: '36px', // min 1960px
    h2_desktop: '36px', // min 1200px
    h2_tablet: '24px', // min 960px
    h2_mobile: '17px', // min 0

    h3_large_desktop: '24px', // min 1960px
    h3_desktop: '24px', // min 1200px
    h3_tablet: '24px', // min 960px
    h3_mobile: '14px', // min 0

    h4_large_desktop: '24px', // min 1960px
    h4_desktop: '18px', // min 1200px
    h4_tablet: '18px', // min 960px
    h4: '14px', // min 0

    body_xl_large_desktop: '24px', // min 1960px
    body_xl_desktop: '24px', // min 1200px
    body_xl_tablet: '24px', // min 960px
    body_xl_mobile: '18px', // min 0

    body_l_large_desktop: '21px', // min 1960px
    body_l_desktop: '21px', // min 1200px
    body_l_tablet: '21px', // min 960px
    body_l_mobile: '16px', // min 0

    body_large_desktop: '16px', // min 1960px
    body_desktop: '16px', // min 1200px
    body_tablet: '16px', // min 960px
    body_mobile: '16px', // min 0

    body_s_large_desktop: '14px', // min 1960px
    body_s_desktop: '14px', // min 1200px
    body_s_tablet: '14px', // min 960px
    body_s_mobile: '14px', // min 0

    eyebrow_text_large_desktop: '14px', // min 1960px
    eyebrow_text_desktop: '14px', // min 1200px
    eyebrow_text_tablet: '14px', // min 960px
    eyebrow_text_mobile: '12px', // min 0

    article_body_large_desktop: '18px', // min 1960px
    article_body_desktop: '18px', // min 1200px
    article_body_tablet: '16px', // min 960px
    article_body_mobile: '16px', // min 0

    small_tag_large_desktop: '14px', // min 1960px
    small_tag_desktop: '14px', // min 1200px
    small_tag_tablet: '12px', // min 960px
    small_tag_mobile: '12px', // min 0

    button_large_desktop: '14px', // min 1960px
    button_desktop: '14px', // min 1200px
    button_tablet: '14px', // min 960px
    button_mobile: '14px', // min 0

    link_large_desktop: '45px', // min 1960px
    link_desktop: '45px', // min 1200px
    link_tablet: '36px', // min 960px
    link_mobile: '17px', // min 0

    copyright_text_large_desktop: '14px', // min 1960px
    copyright_text_desktop: '14px', // min 1200px
    copyright_text_tablet: '14px', // min 960px
    copyright_text_mobile: '10px', // min 0

    small_link_large_desktop: '16px', // min 1960px
    small_link_desktop: '16px', // min 1200px
    small_link_tablet: '16px', // min 960px
    small_link_mobile: '14px', // min 0
  },
  letterSpacing: {
    h1_large_desktop: '0px', // min 1960px
    h1_desktop: '0px', // min 1200px
    h1_tablet: '0px', // min 960px
    h1_mobile: '0px', // min 0

    h2_large_desktop: '0px', // min 1960px
    h2_desktop: '0px', // min 1200px
    h2_tablet: '0px', // min 960px
    h2_mobile: '0px', // min 0

    h3_large_desktop: '0px', // min 1960px
    h3_desktop: '0px', // min 1200px
    h3_tablet: '0px', // min 960px
    h3_mobile: '0px', // min 0

    h4_large_desktop: '0px', // min 1960px
    h4_desktop: '0px', // min 1200px
    h4_tablet: '0px', // min 960px
    h4: '0px', // min 0

    body_xl_large_desktop: '0px', // min 1960px
    body_xl_desktop: '0px', // min 1200px
    body_xl_tablet: '0px', // min 960px
    body_xl_mobile: '0px', // min 0

    body_l_large_desktop: '0px', // min 1960px
    body_l_desktop: '0px', // min 1200px
    body_l_tablet: '0px', // min 960px
    body_l_mobile: '0px', // min 0

    body_large_desktop: '0px', // min 1960px
    body_desktop: '0px', // min 1200px
    body_tablet: '0px', // min 960px
    body_mobile: '0px', // min 0

    body_s_large_desktop: '0px', // min 1960px
    body_s_desktop: '0px', // min 1200px
    body_s_tablet: '0px', // min 960px
    body_s_mobile: '0px', // min 0

    eyebrow_text_large_desktop: '0px', // min 1960px
    eyebrow_text_desktop: '0px', // min 1200px
    eyebrow_text_tablet: '0px', // min 960px
    eyebrow_text_mobile: '0px', // min 0

    article_body_large_desktop: '0px', // min 1960px
    article_body_desktop: '0px', // min 1200px
    article_body_tablet: '0px', // min 960px
    article_body_mobile: '0px', // min 0

    small_tag_large_desktop: '0px', // min 1960px
    small_tag_desktop: '0px', // min 1200px
    small_tag_tablet: '0px', // min 960px
    small_tag_mobile: '0px', // min 0

    button_large_desktop: '0px', // min 1960px
    button_desktop: '0px', // min 1200px
    button_tablet: '0px', // min 960px
    button_mobile: '0px', // min 0

    link_large_desktop: '0px', // min 1960px
    link_desktop: '0px', // min 1200px
    link_tablet: '0px', // min 960px
    link_mobile: '0px', // min 0

    small_link_large_desktop: '0px', // min 1960px
    small_link_desktop: '0px', // min 1200px
    small_link_tablet: '0px', // min 960px
    small_link_mobile: '0px', // min 0
  },
  lineHeight: {
    h1_large_desktop: '125%', // min 1960px
    h1_desktop: '125%', // min 1200px
    h1_tablet: '125%', // min 960px
    h1_mobile: '125%', // min 0

    h2_large_desktop: '125%', // min 1960px
    h2_desktop: '125%', // min 1200px
    h2_tablet: '125%', // min 960px
    h2_mobile: '125%', // min 0

    h3_large_desktop: '125%', // min 1960px
    h3_desktop: '125%', // min 1200px
    h3_tablet: '125%', // min 960px
    h3_mobile: '150%', // min 0

    h4_large_desktop: '125%', // min 1960px
    h4_desktop: '125%', // min 1200px
    h4_tablet: '125%', // min 960px
    h4: '125%', // min 0

    body_xl_large_desktop: '120%', // min 1960px
    body_xl_desktop: '120%', // min 1200px
    body_xl_tablet: '120%', // min 960px
    body_xl_mobile: '120%', // min 0

    body_l_large_desktop: '125%', // min 1960px
    body_l_desktop: '125%', // min 1200px
    body_l_tablet: '125%', // min 960px
    body_l_mobile: '125%', // min 0

    body_large_desktop: '130%', // min 1960px
    body_desktop: '130%', // min 1200px
    body_tablet: '125%', // min 960px
    body_mobile: '20px', // min 0

    body_s_large_desktop: '125%', // min 1960px
    body_s_desktop: '125%', // min 1200px
    body_s_tablet: '125%', // min 960px
    body_s_mobile: '125%', // min 0

    eyebrow_text_large_desktop: '125%', // min 1960px
    eyebrow_text_desktop: '125%', // min 1200px
    eyebrow_text_tablet: '125%', // min 960px
    eyebrow_text_mobile: '125%', // min 0

    article_body_large_desktop: '150%', // min 1960px
    article_body_desktop: '150%', // min 1200px
    article_body_tablet: '150%', // min 960px
    article_body_mobile: '150%', // min 0

    small_tag_large_desktop: '125%', // min 1960px
    small_tag_desktop: '125%', // min 1200px
    small_tag_tablet: '125%', // min 960px
    small_tag_mobile: '125%', // min 0

    button_large_desktop: '130%', // min 1960px
    button_desktop: '130%', // min 1200px
    button_tablet: '130%', // min 960px
    button_mobile: '130%', // min 0

    link_large_desktop: '125%', // min 1960px
    link_desktop: '125%', // min 1200px
    link_tablet: '125%', // min 960px
    link_mobile: '125%', // min 0

    small_link_large_desktop: '156%', // min 1960px
    small_link_desktop: '156%', // min 1200px
    small_link_tablet: '156%', // min 960px
    small_link_mobile: '179%', // min 0

    copyright_text_large_desktop: '130%', // min 1960px
    copyright_text_desktop: '130%', // min 1200px
    copyright_text_tablet: '130%', // min 960px
    copyright_text_mobile: '130%', // min 0
  },
  fontWeight: {
    h1_large_desktop: 900, // min 1960px
    h1_desktop: 900, // min 1200px
    h1_tablet: 900, // min 960px
    h1_mobile: 900, // min 0

    h2_large_desktop: 900, // min 1960px
    h2_desktop: 900, // min 1200px
    h2_tablet: 900, // min 960px
    h2_mobile: 900, // min 0

    h3_large_desktop: 900, // min 1960px
    h3_desktop: 900, // min 1200px
    h3_tablet: 900, // min 960px
    h3_mobile: 900, // min 0

    h4_large_desktop: 900, // min 1960px
    h4_desktop: 900, // min 1200px
    h4_tablet: 900, // min 960px
    h4: 900, // min 0

    body_xl_large_desktop: 500, // min 1960px
    body_xl_desktop: 500, // min 1200px
    body_xl_tablet: 500, // min 960px
    body_xl_mobile: 500, // min 0

    body_l_large_desktop: 400, // min 1960px
    body_l_desktop: 400, // min 1200px
    body_l_tablet: 400, // min 960px
    body_l_mobile: 400, // min 0

    body_large_desktop: 400, // min 1960px
    body_desktop: 400, // min 1200px
    body_tablet: 400, // min 960px
    body_mobile: 400, // min 0

    body_s_large_desktop: 400, // min 1960px
    body_s_desktop: 400, // min 1200px
    body_s_tablet: 400, // min 960px
    body_s_mobile: 400, // min 0

    eyebrow_text_large_desktop: 700, // min 1960px
    eyebrow_text_desktop: 700, // min 1200px
    eyebrow_text_tablet: 700, // min 960px
    eyebrow_text_mobile: 700, // min 0

    article_body_large_desktop: 400, // min 1960px
    article_body_desktop: 400, // min 1200px
    article_body_tablet: 400, // min 960px
    article_body_mobile: 400, // min 0

    small_tag_large_desktop: 700, // min 1960px
    small_tag_desktop: 700, // min 1200px
    small_tag_tablet: 700, // min 960px
    small_tag_mobile: 700, // min 0

    button_large_desktop: 500, // min 1960px
    button_desktop: 500, // min 1200px
    button_tablet: 500, // min 960px
    button_mobile: 500, // min 0

    link_large_desktop: 900, // min 1960px
    link_desktop: 900, // min 1200px
    link_tablet: 900, // min 960px
    link_mobile: 900, // min 0

    small_link_large_desktop: 900, // min 1960px
    small_link_desktop: 900, // min 1200px
    small_link_tablet: 900, // min 960px
    small_link_mobile: 400, // min 0
  },
  scale: (value, by = 0.1) => {
    by = 1 - by;

    if (value.indexOf(' ') >= 0) {
      value = value.split(' ');
      value = value
        .map((v) => `${Math.floor(+v.split('px')[0] * by)}px`)
        .join(' ');
      return value;
    } else {
      value = value.split('px')[0];
      value = value * by;
      return `${Math.floor(value)}px`;
    }
  },
};

export default theme;

export const li = css`
  margin-bottom: 15px;

  :last-of-type {
    margin-bottom: 0;
  }

  ::marker {
    color: ${theme.colors.tertiary};
  }
`;

export const ol = css`
  list-style-position: inside;

  & > li,
  ol > li {
    counter-increment: item;

    ::marker {
      content: '';
    }

    :before {
      display: inline-block;
      width: 1em;
      padding-right: 1.5em;
      font-weight: bold;
      text-align: right;
      content: counter(item);
      color: ${theme.colors.tertiary};
    }
  }

  ul,
  ol {
    padding-top: 15px;
    padding-left: 20px;
  }
`;

export const ul = css`
  list-style-position: inside;

  ul,
  ol {
    padding-top: 15px;
    padding-left: 20px;
  }
`;

export const defaultTheme = css`
  h1, .h1 {
    ${h1};
  }

  h2, .h2 {
    ${h2};
  }

  h3, .h3 {
    ${h3};
  }

  h4, .h4 {
    ${h4}
  }

  h5, .h5 {
    ${h5};
  }

  h6, .h6 {
    ${h6};
  }

  a {
    ${p};
  }

  p,
  input,
  li,
  .body {
    ${p};
  }

  small, .small {
    ${small};
  }

  blockquote, .blockquote{
    ${blockquote};
  }

  li, .li{
    ${li};
  }
`;
