import React from 'react';
import Container from 'components/Container/index';
import {
  StyledFooter,
  FooterContent,
  Text,
  FooterItems,
  Item,
  ItemLink,
  FooterContainer,
} from './styles';

const Footer = ({ backgroundColor, footerColor }) => {
  const isBlueModifier = footerColor === 'blue';

  return (
    <>
      <StyledFooter
        id="footer"
        className={isBlueModifier ? 'blue' : `${backgroundColor}`}
      >
        <FooterContainer>
          <Container
            topSpacingMobile="0"
            topSpacingTablet="0"
            topSpacingDesktop="0"
            bottomSpacingMobile="0"
            bottomSpacingTablet="0"
            bottomSpacingDesktop="0"
            spacedTablet={true}
            spacedDesktop={false}
          >
            <FooterContent className="Footer-Content">
              <Text className="Footer-Text">© Université de Montréal</Text>
              <FooterItems>
                <ItemLink href="https://www.umontreal.ca/" target="_blank">
                  {isBlueModifier ? (
                    <Item
                      alt="Universite de Montreal logo"
                      src="/images/universite-de-montreal-blue.png"
                    />
                  ) : (
                    <Item
                      alt="Universite de Montreal logo"
                      src="/images/universite-de-montreal.png"
                    />
                  )}
                </ItemLink>

                <ItemLink href="https://ageefep.qc.ca/" target="_blank">
                  {isBlueModifier ? (
                    <Item alt="Ageefep logo" src="/images/ageefep-blue.png" />
                  ) : (
                    <Item alt="Ageefep logo" src="/images/ageefep.png" />
                  )}
                </ItemLink>

                <ItemLink href="http://www.faecum.qc.ca/" target="_blank">
                  {isBlueModifier ? (
                    <Item alt="Feacum logo" src="/images/faecum-blue.png" />
                  ) : (
                    <Item alt="Feacum logo" src="/images/faecum.png" />
                  )}
                </ItemLink>
              </FooterItems>
            </FooterContent>
          </Container>
        </FooterContainer>
      </StyledFooter>
    </>
  );
};

export default Footer;
