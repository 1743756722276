import styled from 'styled-components';
import Link from 'gatsby-plugin-transition-link';

export const HeaderStyled = styled.header`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  background-color: transparent;

  &.isArticle .Header-Container {
    padding-top: 30px;
  }

  &.isArticle .BurgerContainer {
    top: 25px;
  }

  &.blue {
    background-color: transparent;

    .Logo-Container {
      .Header-Logo {
        path {
          fill: ${(props) => props.theme.colors.blue};
        }
      }
    }

    .Header-Text {
      color: ${(props) => props.theme.colors.blue};
    }

    .Burger-Menu {
      .Icon-Burgermenu {
        circle {
          fill: ${(props) => props.theme.colors.blue};
        }

        path {
          fill: ${(props) => props.theme.colors.white};
        }
      }
    }
  }

  &.isOpen {
    background-color: transparent;

    .Logo-Container {
      .Header-Logo {
        path {
          fill: ${(props) => props.theme.colors.blue};
        }

        @media (min-width: ${(props) => props.theme.breakpoints.l}) {
          path {
            fill: ${(props) => props.theme.colors.lime};
          }
        }
      }
    }

    .Header-Text {
      color: ${(props) => props.theme.colors.blue};
    }

    .Burger-Menu {
      .Icon-Burgermenu {
        circle {
          fill: ${(props) => props.theme.colors.blue};
        }

        path {
          fill: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 74px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 40px 0 40px;
  display: flex;
  align-items: center;
  background-color: transparent;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 20px 20px 0 20px;
  }
`;

export const BurgerContainer = styled.div`
  position: fixed;
  top: 20px;
  width: 100%;
  background-color: transparent;
`;

export const BurgerContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding-right: 20px;
  }
`;

export const LogoContainer = styled(Link)`
  margin-right: auto;
  max-width: 255px;
  cursor: pointer;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: 152px;
  }
`;

export const BurgerMenu = styled.button`
  position: relative;
  z-index: 1;
  width: 54px;
  height: 55px;
  cursor: pointer;
  background-color: transparent;

  .Icon-Burgermenu {
    circle {
      fill: ${(props) => props.theme.colors.white};
    }

    path {
      fill: ${(props) => props.theme.colors.blue};
    }
  }

  &.isOpen {
    background-color: ${(props) => props.theme.colors.blue};
    background-image: url('/images/burger-plus.svg');
    background-repeat: no-repeat;
    background-position: center;

    .Icon-Burgermenu {
      display: none;
    }
  }
`;

export const Text = styled.p`
  margin: 0 61px 0 7px;
  font-size: ${(props) => props.theme.fonts.small_tag_mobile};
  font-weight: ${(props) => props.theme.fontWeight.small_tag_mobile};
  line-height: ${(props) => props.theme.lineHeight.small_tag_mobile};
  letter-spacing: ${(props) => props.theme.letterSpacing.small_tag_mobile};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.small_tag_tablet};
    line-height: ${(props) => props.theme.lineHeight.small_tag_tablet};
    letter-spacing: ${(props) => props.theme.letterSpacing.small_tag_tablet};
    font-weight: ${(props) => props.theme.fontWeight.small_tag_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.small_tag_desktop};
    line-height: ${(props) => props.theme.lineHeight.small_tag_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.small_tag_desktop};
    font-weight: ${(props) => props.theme.fontWeight.small_tag_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    font-size: ${(props) => props.theme.fonts.small_tag_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.small_tag_large_desktop};
    letter-spacing: ${(props) =>
      props.theme.letterSpacing.small_tag_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.small_tag_large_desktop};
  }
`;
